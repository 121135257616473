import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import merge from 'classnames';

import { Button } from 'components/generic/Button';
import { Pattern } from 'components/generic/Pattern';
import { IconErrorOutline } from 'components/icons/IconErrorOutline';
import { IconHome } from 'components/icons/IconHome';
import { IconLeaderboard } from 'components/icons/IconLeaderboard';
import { IconMoreHorizontal } from 'components/icons/IconMoreHorizontal';
import { EXTERNAL_ROUTES } from 'router/variables';
import { NavigationService } from 'services/Navigation';
import { AnalyzeMode } from 'store/reducers/property/types';

import { Props } from './types';

import styles from './BottomNavigation.module.scss';

const {
  Private: {
    Account,
    Analyze,
    Dashboard,
    Referral,
    Support: { ContactUs },
  },
} = NavigationService.ROUTES;

export const BottomNavigation = (props: Props) => {
  const location = useLocation();
  const [moreModalIsOpened, setMoreModalIsOpened] = useState(false);
  const [supportModalIsOpened, setSupportModalIsOpened] = useState(false);

  if (!props.isUserLoggedIn) return null;

  const isActive = (value: string | string[]): boolean => {
    return (
      (location.pathname === value || value.includes(location.pathname)) &&
      (!moreModalIsOpened || !supportModalIsOpened)
    );
  };

  const navigate = (pathName: string, external?: boolean) => {
    setMoreModalIsOpened(false);
    setSupportModalIsOpened(false);
    if (!external) NavigationService.goTo(pathName);

    if (external) window.open(pathName, '_blank', 'noreferrer');
  };

  return (
    <>
      <div className={styles.navigation} data-testid={BottomNavigation._jestTestId}>
        <button
          onClick={() => navigate(Dashboard)}
          className={merge(styles.button, { [styles.active]: isActive(Dashboard) })}
        >
          <IconHome classes={{ container: styles.icon }} />
          <span>Home</span>
        </button>

        <button
          onClick={() => {
            if (props.hasPropertyToAnalyze) {
              setMoreModalIsOpened(false);
              NavigationService.goTo(Analyze, { state: { mode: AnalyzeMode.Day } });
            }
          }}
          className={merge(styles.button, { [styles.active]: isActive(Analyze) })}
          disabled={!props.hasPropertyToAnalyze}
        >
          <IconLeaderboard
            color={props.hasPropertyToAnalyze ? '#002e5d' : '#d0d1d1'}
            classes={{ container: merge(styles.icon, styles.mirror) }}
          />
          <span>Analyze</span>
        </button>

        <button
          className={merge(styles.button, {
            [styles.active]: supportModalIsOpened || isActive(ContactUs),
          })}
          onClick={() => {
            setMoreModalIsOpened(false);
            setSupportModalIsOpened(!supportModalIsOpened);
          }}
        >
          <IconErrorOutline classes={{ container: merge(styles.icon, styles.rotated180) }} />
          <span>Support</span>
        </button>

        <button
          className={merge(styles.button, {
            [styles.active]: moreModalIsOpened || isActive(Referral),
          })}
          onClick={() => {
            setMoreModalIsOpened(!moreModalIsOpened);
            setSupportModalIsOpened(false);
          }}
        >
          <IconMoreHorizontal classes={{ container: styles.icon }} />
          <span>More</span>
        </button>
      </div>

      <Modal
        backdrop={false}
        isOpen={moreModalIsOpened || supportModalIsOpened}
        toggle={() => {
          setSupportModalIsOpened(false);
          setMoreModalIsOpened(false);
        }}
        wrapClassName={styles.more_modal}
        data-testid="modal"
      >
        <ModalBody>
          <div className={styles.menu}>
            {moreModalIsOpened && (
              <>
                <Button
                  size="large"
                  color="blue"
                  colorSchema="filled"
                  className={styles.button}
                  onClick={() => navigate(Account)}
                >
                  Your account
                </Button>

                {props.isEligibleForReferralProgram && (
                  <Button
                    size="large"
                    color="blue"
                    colorSchema="filled"
                    className={styles.button}
                    onClick={() => navigate(Referral)}
                  >
                    Refer a friend
                  </Button>
                )}

                <Button
                  size="large"
                  color="blue"
                  colorSchema="filled"
                  className={styles.logout_button}
                  loading={props.loggingUserOut}
                  onClick={() => props.logOutUser()}
                >
                  Sign out
                </Button>
              </>
            )}

            {supportModalIsOpened && (
              <>
                <Button
                  size="large"
                  color="blue"
                  colorSchema="filled"
                  className={styles.button}
                  onClick={() => navigate(ContactUs)}
                >
                  Contact us
                </Button>

                <Button
                  size="large"
                  color="blue"
                  colorSchema="filled"
                  className={styles.button}
                  onClick={() => navigate(EXTERNAL_ROUTES.FAQ, true)}
                >
                  FAQ
                </Button>
              </>
            )}
          </div>

          <Pattern className={styles.pattern} />
        </ModalBody>
      </Modal>
    </>
  );
};

BottomNavigation._jestTestId = 'bottom-navigation';
